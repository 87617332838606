import * as React from "react";

import { PageLayout, SEO } from "@components/page-layout";

import { GatsbyLink } from "@components/gatsby-link";
import { Heading, Paragraph, GrayPhrase } from "@components/typography";
import { Box } from "@components/box";
import img404 from "@images/404-image.jpg";

const NotFoundPage = () => (
  <PageLayout>
    <SEO title="404: Not found" />
    <Box
      className="mx-auto text-center my-[8.25rem] md:mt-[5rem]"
      styledCss="max-width:660px;"
    >
      <Heading className="text-[68px] mb-[1.75rem] md:text-[50px]">404</Heading>
      <Paragraph className="pb-[4.5rem]">
        <GrayPhrase>
          We could not find this page. Check that you typed the address
          correctly or click <GatsbyLink to="/">here</GatsbyLink> to open iCash
          principal page to view products we offer to get you back on track to
          financial health anywhere, at any time.
        </GrayPhrase>
      </Paragraph>
      <Box className="overflow-hidden rounded-[3rem]">
        <img src={img404} alt="404 not found" className="w-full" />
      </Box>
    </Box>
  </PageLayout>
);

export default NotFoundPage;
